<template>
  <div class="security-page">
    <m-page-title
      title="Update Personal Profile"
      describe="*indicates required field"
    />
    <h3 class="item-title">Password*</h3>
    <el-form
      :model="fromData"
      ref="loginForm"
      :rules="rules"
      label-position="top"
      size="medium"
      @keyup.enter.native="saveClick"
      @submit.native.prevent
    >
      <el-form-item label="Old Password" prop="oldPassword">
        <el-input
          v-model="fromData.oldPassword"
          show-password
          class="form-input"
        ></el-input>
      </el-form-item>
      <div class="line">
        <el-form-item label="New Password" prop="newPassword">
          <el-input
            v-model="fromData.newPassword"
            show-password
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="Confirm Password" prop="confirmPassword">
          <el-input
            v-model="fromData.confirmPassword"
            type="password"
            class="form-input"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div class="flex-row btn-box">
      <m-back />
      <el-button class="save-btn" type="primary" @click="saveClick">
        Save
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "basicInfo",
  components: {},
  data() {
    const confirmPassword = (rule, value, callback) => {
      if (value !== this.fromData.newPassword) {
        callback(new Error("Password are not matching"));
      } else {
        callback();
      }
    };
    this.rules = {
      oldPassword: [
        {
          required: true,
          message: "Password cannot be empty",
          trigger: "blur",
        },
      ],
      newPassword: [
        {
          required: true,
          message: "Password cannot be empty",
          trigger: "blur",
        },
        {
          min: 8,
          message: "Please enter at least 8 digits of numbers or letters",
          trigger: "blur",
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: "Please enter the password again",
          trigger: "blur",
        },
        { validator: confirmPassword, trigger: "blur" },
      ],
    };
    return {
      fromData: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {},
  methods: {
    async saveClick() {
      if (!(await this.$refs.loginForm.validate())) return false;
      const data = {
        oldPassword: this.fromData.oldPassword,
        newPassword: this.fromData.confirmPassword,
      };
      this.$axios.post(this.$api.updatePass, data).then((res) => {
        if (res.code === "1000") {
          this.$notify.success("Modified successfully");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
